/* Farbdefinitionen */
:root {
  --sanitaetsdienste: #ff8c00;
  --blutspenden: #e60005;
  --fahrzeugbelegungen: #508650;
  --ausbildungen: #fec300;
  --raumbelegungen: #660066;
  --dienstabende: #188ca8;
  --veranstaltungen: #c71585;
  --c-dienst: #8b0000;
  --geburtstage: #116fbf;
  --tuv: #333d99;

  --aufnahme-h1: #147d6c;
  --aufnahme-h2: #1057a7;
  --aufnahme-h3: #9fa4a7;
}

/* Allgemeine Einstellungen */
body .p-togglebutton.filteritem {
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
}

div.p-togglebutton.p-focus {
  box-shadow: none !important;
}

body .p-accordion .p-accordion-header.filteritem:not(.p-disabled).p-highlight a {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: #333333;
  font-weight: 700;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-accordion .p-accordion-header.filteritem:not(.p-disabled).p-highlight a .p-accordion-toggle-icon {
  color: #333333 !important;
}

.fc-scroller {
  height: 100% !important;
}

/* Sanitätsdienste */
.fc-event-container .sanitaetsdienste,
body .p-togglebutton.p-highlight.filteritem.sanitaetsdienste {
  background-color: var(--sanitaetsdienste) !important;
  border-color: var(--sanitaetsdienste) !important;
  color: #000000 !important;
}

body .p-togglebutton.filteritem.sanitaetsdienste {
  color: var(--sanitaetsdienste) !important;
  transition: background-color 0.2s, box-shadow 0.2s !important;
}

body .p-togglebutton.filteritem.sanitaetsdienste:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--sanitaetsdienste) !important;
}

/* Blutspenden */
.fc-event-container .blutspenden,
body .p-togglebutton.p-highlight.filteritem.blutspenden {
  background-color: var(--blutspenden) !important;
  border-color: var(--blutspenden) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.blutspenden {
  color: var(--blutspenden) !important;
  transition: background-color 0.2s, box-shadow 0.2s !important;
}

body .p-togglebutton.filteritem.blutspenden:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--blutspenden) !important;
}

/* Fahrzeugbelegungen */
.fc-event-container .fahrzeugbelegungen,
body .p-togglebutton.p-highlight.filteritem.fahrzeugbelegungen {
  background-color: var(--fahrzeugbelegungen) !important;
  border-color: var(--fahrzeugbelegungen) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.fahrzeugbelegungen {
  color: var(--fahrzeugbelegungen) !important;
}

body .p-togglebutton.filteritem.fahrzeugbelegungen:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--fahrzeugbelegungen) !important;
}

/* Raumbelegungen */
.fc-event-container .raumbelegungen,
body .p-togglebutton.p-highlight.filteritem.raumbelegungen {
  background-color: var(--raumbelegungen) !important;
  border-color: var(--raumbelegungen) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.raumbelegungen {
  color: var(--raumbelegungen) !important;
}

body .p-togglebutton.filteritem.raumbelegungen:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--raumbelegungen) !important;
}

/* Ausbildungen */
.fc-event-container .ausbildungen,
body .p-togglebutton.p-highlight.filteritem.ausbildungen {
  background-color: var(--ausbildungen) !important;
  border-color: var(--ausbildungen) !important;
  color: #000000 !important;
}

body .p-togglebutton.filteritem.ausbildungen {
  color: var(--ausbildungen) !important;
}

body .p-togglebutton.filteritem.ausbildungen:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--ausbildungen) !important;
}

/* Dienstabende */
.fc-event-container .dienstabende,
body .p-togglebutton.p-highlight.filteritem.dienstabende {
  background-color: var(--dienstabende) !important;
  border-color: var(--dienstabende) !important;
  color: #000000 !important;
}

body .p-togglebutton.filteritem.dienstabende {
  color: var(--dienstabende) !important;
}

body .p-togglebutton.filteritem.dienstabende:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--dienstabende) !important;
}

/* Veranstaltungen */
.fc-event-container .veranstaltungen,
body .p-togglebutton.p-highlight.filteritem.veranstaltungen {
  background-color: var(--veranstaltungen) !important;
  border-color: var(--veranstaltungen) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.veranstaltungen {
  color: var(--veranstaltungen) !important;
}

body .p-togglebutton.filteritem.dienstabende:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--dienstabende) !important;
}

/* C-Dienst */
.fc-event-container .c-dienst,
body .p-togglebutton.p-highlight.filteritem.c-dienst {
  background-color: var(--c-dienst) !important;
  border-color: var(--c-dienst) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.c-dienst {
  color: var(--c-dienst) !important;
}

body .p-togglebutton.filteritem.c-dienst:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--c-dienst) !important;
}

/* Geburtstage */
.fc-event-container .geburtstage,
body .p-togglebutton.p-highlight.filteritem.geburtstage {
  background-color: var(--geburtstage) !important;
  border-color: var(--geburtstage) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.geburtstage {
  color: var(--geburtstage) !important;
}

body .p-togglebutton.filteritem.geburtstage:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--geburtstage) !important;
}

/* TÜV */
.fc-event-container .tuv,
body .p-togglebutton.p-highlight.filteritem.tuv {
  background-color: var(--tuv) !important;
  border-color: var(--tuv) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.tuv {
  color: var(--tuv) !important;
}

body .p-togglebutton.filteritem.tuv:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--tuv) !important;
}

/* Aufnahme H1 */
.fc-event-container .aufnahme-h1,
body .p-togglebutton.p-highlight.filteritem.aufnahme-h1 {
  background-color: var(--aufnahme-h1) !important;
  border-color: var(--aufnahme-h1) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.aufnahme-h1 {
  color: var(--aufnahme-h1) !important;
}

body .p-togglebutton.filteritem.aufnahme-h1:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--aufnahme-h1) !important;
}

/* Aufnahme H2 */
.fc-event-container .aufnahme-h2,
body .p-togglebutton.p-highlight.filteritem.aufnahme-h2 {
  background-color: var(--aufnahme-h2) !important;
  border-color: var(--aufnahme-h2) !important;
  color: #ffffff !important;
}

body .p-togglebutton.filteritem.aufnahme-h2 {
  color: var(--aufnahme-h2) !important;
}

body .p-togglebutton.filteritem.aufnahme-h2:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--aufnahme-h2) !important;
}

/* Aufnahme H3 */
.fc-event-container .aufnahme-h3,
body .p-togglebutton.p-highlight.filteritem.aufnahme-h3 {
  background-color: var(--aufnahme-h3) !important;
  border-color: var(--aufnahme-h3) !important;
  color: #000000 !important;
}

body .p-togglebutton.filteritem.aufnahme-h3 {
  color: var(--aufnahme-h3) !important;
}

body .p-togglebutton.filteritem.aufnahme-h3:not(.p-disabled):not(.p-highlight):hover {
  border: 1px solid var(--aufnahme-h3) !important;
}
