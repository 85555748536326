@import url('./styles/changelog.css');
@import url('./styles/kalender.css');
@import url('./styles/mobile.css');

.p-organizationchart .p-organizationchart-node-content {
  padding: 0 !important;
  border: 0 none !important;
}

.node-header,
.node-content {
  padding: 0.5em 0.7em;
}

.node-header {
  background-color: #495ebb;
  color: #ffffff;
}

.node-content {
  text-align: center;
  border: 1px solid #495ebb;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight a,
nav a {
  color: #ffffff !important;
}

.form .form-group {
  margin: 1.2rem 0;
}

@media screen and (min-width: 1864px) {
  .container {
    max-width: 1800px !important;
  }
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landing {
  position: relative;
  height: 100vh;
}

.landing0 {
  background: url('./img/landing.jpg') no-repeat center center/cover;
}

.landing1 {
  background: url('./img/landing1.jpg') no-repeat center center/cover;
}

.landing2 {
  background: url('./img/landing2.jpg') no-repeat center center/cover;
}

.landing3 {
  background: url('./img/landing3.jpg') no-repeat center center/cover;
}

.landing4 {
  background: url('./img/landing4.jpg') no-repeat center center/cover;
}

.landing-inner {
  color: #ffffff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ausreichend-besetzt,
.is-bevorzugt {
  background: #90ee90 !important;
}

.nicht-ausreichend-besetzt {
  background: #f08080 !important;
}

.abgesagt {
  background: rgba(255, 170, 0, 1) !important;
}

.wichtige-nachricht.nicht-ausreichend-besetzt {
  background: linear-gradient(225deg, rgba(255, 204, 0, 1) 50%, #f08080 50%) !important;
}

.wichtige-nachricht.ausreichend-besetzt {
  background: linear-gradient(225deg, rgba(255, 170, 0, 1) 50%, #90ee90 50%) !important;
}

.ql-align-center,
.is-center {
  text-align: center !important;
}

.p-chips.p-component.is-full-width ul,
.p-calendar.is-full-width .p-inputtext.p-component.p-inputtext.p-component,
.p-dropdown-filter-container,
.react-datepicker-wrapper,
.is-full-width {
  width: 100% !important;
}

.is-monospace {
  font-family: monospace;
}

.is-red {
  color: #ff0000;
}

.is-green {
  color: #04aa04;
}

.is-black {
  color: #000000 !important;
}

.hero-body {
  padding: 1rem 1em 0 !important;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #007ad9 !important;
  color: #ffffff !important;
}

.is-12px {
  font-size: 12px !important;
}

.is-13px {
  font-size: 13px !important;
}

.is-14px {
  font-size: 14px !important;
}

.is-15px {
  font-size: 15px !important;
}

.width-16em {
  width: 16em !important;
}

.float-label {
  top: -0.75em !important;
  font-size: 12px !important;
}

.is-vertical-middle {
  vertical-align: middle !important;
}

h1 {
  color: #363636;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

h2 {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

.only-show-mobile {
  display: none;
}

.menu-button {
  background-color: transparent !important;
  border: 0 transparent !important;
  font-size: 20px !important;
}

.error {
  margin: 2em 10em;
  text-align: center;
}

.error .title.head {
  font-size: 100pt !important;
}

.error-button {
  margin: auto !important;
  display: block !important;
}

.full-opacity {
  opacity: 1 !important;
}

.is-ausgeblendet {
  background-color: #808080 !important;
  color: #d3d3d3 !important;
}

.ist-unsichtbar,
.is-abgerechnet {
  background-color: #d3d3d3 !important;
  color: #808080 !important;
}

.pad-right_0 {
  padding-right: 0 !important;
}

.pad-left_0 {
  padding-left: 0 !important;
}

.mg-top_0 {
  margin-top: 0 !important;
}

.mg-top-1em {
  margin-top: 1em !important;
}

.mg-bottom_0 {
  margin-bottom: 0 !important;
}

.mg-left-3em {
  margin-left: 3em !important;
}

body .p-menubar .p-submenu-list {
  width: max-content !important;
}

.text-vertical-middle {
  vertical-align: middle !important;
}

.margin-top-1_2rem {
  margin-top: 1.2rem;
}

body .p-rating.is-30px .p-rating-icon {
  font-size: 30px !important;
}

.fa-file-word {
  color: #2b569a;
}

.fa-file-excel {
  color: #217345;
}

.fa-file-powerpoint {
  color: #b6472b;
}

.fa-file-pdf {
  color: #ff1b0e;
}

.rdQuali-grau {
  background-color: #4a4a4a;
  color: #ffffff;
}

.rdQuali-gruen {
  background-color: #2e8b57;
  color: #000000;
}

.rdQuali-gelb {
  background-color: #daa520;
  color: #000000;
}

.rdQuali-rot {
  background-color: #b22222;
  color: #ffffff;
}

.p-slidemenu {
  height: 100% !important;
  border: none !important;
  margin: 0 !important;
}

.p-slidemenu .p-slidemenu-wrapper {
  height: 100% !important;
}

body .p-slidemenu .p-slidemenu-backward {
  background-color: transparent !important;
}

.abzeichen {
  width: 150px;
  height: 150px;
}

.u16 {
  background-color: #00a1df;
}

.u18 {
  background-color: #e4010c;
  color: #ffffff;
}

.unterstrichen {
  text-decoration: underline;
}

.doppelt-unterstrichen {
  text-decoration: double;
}

.progress-bar-green .p-progressbar-value {
  background-color: #34a835 !important;
}

.progress-bar-yellow .p-progressbar-value {
  background-color: #ffba01 !important;
}

.progress-bar-orange .p-progressbar-value {
  background-color: #ff8c01 !important;
}

.progress-bar-red .p-progressbar-value {
  background-color: #e91224 !important;
}

.sigCanvas {
  border: 1px solid #000000;
}
