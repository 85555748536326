@media screen and (max-width: 576px) {
  .hero.is-dark .title {
    font-size: 1.6em;
    text-align: center;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem-active > .p-submenu-list,
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100% !important;
    text-align: center;
  }

  .p-tabview.p-tabview-top > .p-tabview-nav li,
  .p-tabview.p-tabview-top > .p-tabview-nav li a {
    width: 100%;
  }

  .p-tabview .p-tabview-nav li .p-tabview-title {
    white-space: pre-wrap;
  }

  .only-show-mobile {
    display: block;
  }

  button.only-icon-mobile {
    width: 2.357em;
  }

  button.only-icon-mobile .p-button-text {
    padding: 0.25em;
    text-indent: -9999999px;
  }

  .title.text-mobile {
    font-size: 1.5rem;
  }

  .error {
    margin: 0;
    margin-top: 1em;
  }

  .error .title.head {
    font-size: 64pt !important;
  }

  .error .title.is-1 {
    font-size: 2rem !important;
  }

  .error-button {
    margin: 15px auto !important;
  }
  .mobile-full-width,
  .mobile-full-width .p-inputtext {
    width: 100% !important;
  }

  .mobile-mg-left-10px {
    margin-left: 10px !important;
  }

  .mobile-no-margin {
    margin: 0 !important;
  }

  section.p-grid .p-col {
    width: 100% !important;
    flex-basis: unset !important;
  }
}
